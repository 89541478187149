import React from 'react'

export default function Home() {
    return (
       <nav>
    <ul>
        <li><a href="#">Home</a></li>
        <li><a href="#">Quienes Somos</a></li>
        <li><a href="#">Contactanos</a></li>
    </ul>
</nav>

    )
}
